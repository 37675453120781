import './App.css';
import logo from "./assets/Banner8.png"
import instagram from "./assets/instagram.png"
import facebook from "./assets/facebookLogo.png"
import placeholder from "./assets/PlaceHolder.gif"
import { Link, Router } from 'react-router-dom';
import Acceuil from './Accueil';
import Carte from './Carte';
import Galerie from './Galerie';
import { Routes, Route} from 'react-router-dom';
function App() {
  return (
   
    <Routes>
        <Route path="/" element={<Acceuil/>}></Route>
        <Route path="/Carte"  element={<Carte/>}/>
        <Route path="/Galerie" element={<Galerie/>}/>
      </Routes>
     
    
  );
}

export default App;
